import { createContext, ReactNode, useEffect, useState } from 'react';

import { useCreateOrganization, useGetOrganization, useUpdateOrganization } from 'api/Organization';
import { Organization, OrganizationMembership } from '../services/ApiService';
import useAuth from '../hooks/useAuth';
import { useQueryClient } from 'react-query';
import { PLAN_TYPE } from '../sections/_dashboard/subscriptions/PLAN_TYPE';
import useLocalStorage from '../hooks/useLocalStorage';
import { CheckBoxOutlineBlank } from '@mui/icons-material';

// ----------------------------------------------------------------------

type OrganizationContextProps = {
  isLoading: boolean;
  selectedOrganization?: Organization;
  selectedOrganizationId?: number;
  subscriptionPlan: { name: string; planType: PLAN_TYPE };
  usersOrganizationmembership?: OrganizationMembership[] | null;
  updateOrganization: (values: Organization) => Promise<void>;
  createOrganization: (values: Organization) => Promise<void>;
  setSelectedOrganizationId: (organizationId: number) => void;
};

const OrganizationContext = createContext<OrganizationContextProps | null>(null);

function OrganizationProvider({ children }: { children: ReactNode }) {
  const { user, userIsLoading, isAuthenticated } = useAuth();

  const [selectedOrganizationId, setSelectedOrganizationId] = useLocalStorage<number>(
    'selectedOrganizationId',
    null
  );

  useEffect(() => {
    // Select Default Organization.
    // If Selected Org is not set and there are memberships for the user.
    if (!!user && user.organizationMembership && !selectedOrganizationId) {
      setSelectedOrganizationId(user.organizationMembership[0].organizationId);
    }
  }, [user, selectedOrganizationId, setSelectedOrganizationId]);

  const queryClient = useQueryClient();
  useEffect(() => {
    // Reload everything when selected org is changed.
    // We could get more performance by being specific by using exported query keys.
    // but this will ensure all new things are cleared too.
    // Things to potentially leave out, User and s3files
    queryClient.invalidateQueries();
  }, [selectedOrganizationId, queryClient]);

  const {
    data: selectedOrganization,
    isLoading,
    isError
  } = useGetOrganization(selectedOrganizationId, !!isAuthenticated);

  // This is if local storage loads an org that the user no longer has access to.
  useEffect(() => {
    if (isError) {
      // If the Selected Organization data for the requested id Id that is requested isnt returned or has an error,
      // then set setSelectedOrganizationId to null. (this will trigger re-set based on users auth)
      setSelectedOrganizationId(null);
    }
  }, [isError, setSelectedOrganizationId]);

  // User Data
  const mutateUpdateOrganization = useUpdateOrganization();
  const mutateCreateOrganization = useCreateOrganization();

  const updateOrganization = async (values: Organization) => {
    await mutateUpdateOrganization.mutateAsync(values);
  };
  const createOrganization = async (values: Organization) => {
    await mutateCreateOrganization.mutateAsync(values);
  };

  return (
    <OrganizationContext.Provider
      value={{
        isLoading: userIsLoading || isLoading,
        selectedOrganization,
        selectedOrganizationId: selectedOrganization?.organizationId,
        // Todo Add plan type
        subscriptionPlan: { name: 'Free', planType: PLAN_TYPE.STARTER },
        usersOrganizationmembership: user.organizationMembership,
        updateOrganization,
        createOrganization,
        setSelectedOrganizationId
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
}

export { OrganizationContext, OrganizationProvider };
